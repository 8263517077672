import { useEffect, useRef } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";
import { chunk, isEmpty } from "lodash";

export default function RenderGeometryLabel({
  data,
  isShowLabel,
  handleLabelClick,
}) {
  const map = useMap();
  const currentLayer = useRef(null);
  const mainFeatureGroup = L.featureGroup();

  useEffect(() => {
    if (currentLayer.current) {
      currentLayer.current.clearLayers();
    }

    if (!isShowLabel || !data) {
      return null;
    }

    const componentLabelChunk = chunk(data.ComponentLabel_List, 10);

    componentLabelChunk.forEach((componentLabels) => {
      componentLabels.forEach((label) => {
        const coordinate = label.geometry_json.coordinates;
        if (!isEmpty(coordinate)) {
          const html =
            '<div style="min-width:60px;padding: 15px 0;color: #000000; font-size: 10px !important;">' +
            label.text +
            "</div>";
          const myIcon = L.divIcon({
            className: "node-label-text",
            html: html,
          });
          const marker = L.marker(
            Array.isArray(coordinate) ? coordinate[0] : coordinate,
            {
              icon: myIcon,
            }
          );
          marker.on("click", () => {
            handleLabelClick(label);
          });

          mainFeatureGroup.addLayer(marker);
        }
      });
    });

    mainFeatureGroup.addTo(map);
    currentLayer.current = mainFeatureGroup;
    return () => {
      map.removeLayer(currentLayer.current);
      currentLayer.current = null;
    };
  }, [data, isShowLabel]); // eslint-disable-line

  return null;
}
